<template>
  <v-card
    flat
    class="pa-3 mt-1"
  >
    <v-card-title class="d-flex align-center justify-center py-0">
      <div
        class="d-flex align-center"
      >
        <v-img
          :src="require(`@/assets/images/logos/logo.png`)"
          max-width="300px"
          alt="logo"
          contain
          class="me-0 "
        ></v-img>
      </div>
    </v-card-title>
    <v-form class="multi-col-validation">
      <v-card-text class="pt-1">
        <p class="text-h6 text-center">Make a Deposit</p>
        <v-row>
          <v-col cols="10" class="py-0 my-1">
            <v-text-field
              v-model="userName"
              outlined dense
              label="Search username, phone number, unique code..."
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="py-0 my-1">
            <v-btn
              color="success"
              class="me-3"
              fab small
            >
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-select
              v-model="selectedUser"
              :items="users"
              outlined dense
              label="Select a user"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-text-field
              v-model="phoneNumber"
              outlined
              dense
              label="User Phone Number"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-select
              v-model="selectedCampaign"
              outlined
              dense
              :items="campaigns"
              label="Select Campaign"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-text-field
              v-model="amount"
              outlined
              dense
              label="Amount"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <!-- current password -->
            <v-text-field
              v-model="currentPassword"
              :type="isCurrentPasswordVisible ? 'text' : 'password'"
              :append-icon="isCurrentPasswordVisible ? mdiEyeOffOutline:mdiEyeOutline"
              label="Input Current Password"
              outlined
              dense
              @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="text-end">
        <v-btn
          color="primary"
          class="me-3 mt-3"
        >
          Deposit
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'

export default {
  setup() {
    const currentPassword = ref('12345678')
    const isCurrentPasswordVisible = ref(false)
    const userName = ''
    const selectedUser = ''
    const phoneNumber = ''
    const selectedCampaign = ''
    const amount = ''

    return {
      userName,
      selectedUser,
      phoneNumber,
      selectedCampaign,
      isCurrentPasswordVisible,
      currentPassword,
      amount,
      users: [
        'Foo',
        'Bar',
        'Fizz',
        'Buzz',
      ],
      campaigns: [
        'Foo',
        'Bar',
        'Fizz',
        'Buzz',
      ],
      mdiKeyOutline,
      mdiLockOpenOutline,
      mdiEyeOffOutline,
      mdiEyeOutline,
    }
  },
}
</script>
